import React from "react";
import "../../App.css";
import WorkshopSection from "../WorkshopSection";

function WorkshopCourses() {
  return (
    <>
      <WorkshopSection />
    </>
  );
}

export default WorkshopCourses;
