import React from "react";
import "../../App.css";
import PersonalProjSection from "../PersonalProjSection";

function PersonalProjects() {
  return (
    <>
      <PersonalProjSection />
    </>
  );
}

export default PersonalProjects;
