import React from "react";
import "../../App.css";
import AboutSection from "../AboutSection";
import WorkshopSection from "../WorkshopSection";

function About() {
  return (
    <>
      <WorkshopSection />
    </>
  );
}

export default About;
