import React from "react";
import "../../App.css";
import ClassProjSection from "../ClassProjSection";

function ClassProjects() {
  return (
    <>
      <ClassProjSection />
    </>
  );
}

export default ClassProjects;
