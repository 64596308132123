import ZoomComponent from "./ZoomComponent";

let timelineElements = [
    {
      id: 1,
      title: "PCB Design",
      description:
        "Our team used two PCBs: an H-bridge and a tape sensing circuit, which I designed in KiCad. In this section I mainly discuss the considerations that went into creating them.",
      icon: "pcb",
      content:
        <div>
          In the weeks leading up to the full-time development stage,
          we spent time completing important tasks for the robots that didn't involve physically building them or their parts.
          We brainstormed our strategy and design, and created the schematics for the circuits we needed.
          <br /><br />
          Two of the circuits in particular, the H-bridge and the tape sensing circuit, 
          were chosen to be turned into PCBs, for a variety of reasons. First of all, our design required 6 DC motors, 1 for each wheel 
          (we used mecanum wheels, and each needs to be controlled separately), and 1 for each of the elevator platform and sweeper arm.
          Each motor had to be able to be powered in either direction, which meant we would need 6 H-bridges. On top of that, this year's 
          competition required two robots for the first time ever! So we actually needed 12 H-bridges... soldering 12 H-bridges onto protoboards 
          would take an eternity, so we knew we needed to design PCBs. I also made them into double H-bridges (so as not to waste any pins of the ICs), 
          meaning we only needed 6 of them.
          <br /><br />As for the tape sensing circuit, we knew we would need at least two, and since the design was definitely finalized, 
          there were only good things to come from taking the time earlier on to make it a PCB. 
          I was eager to get working on the beginnings of the robot, and as the only person on our team who had experience designing PCBs, I was given that responsibility. 
          <br /><br />
          Below is the schematic capture of the double H-bridge circuit. I'll explain some of the concepts surrounding its' design, and then I'll talk about the PCB, followed by the same thing for the tape sensing circuit.
          <ZoomComponent 
            url="/images/doublehbridgeschematic.png"
            alt="Double H-bridge KiCad Schematic"
            className="bubs"
            buttonTop="26px"
            buttonLeft="700px"  
          />
          <br />The goal of the H-bridge is to allow the motor to be spun in either direction, with the direction being determined by which of two PWM pins is outputting a signal from the MCU. 
          To do this the circuit uses a standard configuration of 4 MOSFETs, which alternate the paths in which current can flow, allowing the signal to flow from positive to negative on the motor or from negative to positive. 
          However, there are numerous other aspects of the H-bridge that are very important, each for their own reasons. 
          <br /><br />Starting from the left hand side of the schematic, you can see that the signals generated by the MCU pass through an MCT6, an optocoupler. 
          The optocoupler physically disconnects the portions of the circuit on either of its' sides. This is accomplished while maintaining the PWM signal by having the input signal drive an LED, whose pulses of light are detected by a corresponding phototransistor. 
          This physical disconnection is extremely useful, since motors running at 12V (the 15V indicated in the schematic was later modified) create plenty of noise, which could easily be transferred back to the MCU if it wasn't optoisolated, through the GND line for example, causing the MCU to reboot or act unexpectedly. 
          <br /><br />The optoisolated signal is then pulled up to the voltage of the larger battery, and passed to a gate driver. This IC is required to deliver sufficient current to the gates of the MOSFETs. The MCU and MCT6 can only output current in the order of mA. 
          Now that the gate driver has allowed the PWM signals to drive the gates, the array of MOSFETs come into play. 
          It is fairly easy to see how the transistors manipulate the flow of current if you take a minute to follow the connections, 
          making sure to keep in mind that only one of G1/G2 and G3/G4 will ever be outputting a PWM signal at a time. 
          <br /><br /><br /><br />That was the essence of our H-bridge, and now I will dive into the PCB itself. 
          The goal was to create something that would most importantly work, without noise issues or any components heating up excessively. Our robot was not low on space, since we could mount circuits on the inside walls, 
          although I still aimed to make it compact. 
          <div className="content-wrapper">
            <div className="image-content">
              <ZoomComponent 
                url="/images/hbridgepcbeditor.png"
                alt="Double H-bridge KiCad PCB Editor"
                className="jm-left"
                buttonTop="26px"
                buttonLeft="260px"  
              />            
            </div>
            <div className="image-content">
            <ZoomComponent 
                url="/images/hbridge3dmodel.png"
                alt="Double H-bridge KiCad 3D Viewer"
                className="gm-right"
                buttonTop="26px"
                buttonLeft="205px"  
              /> 
            </div>
          </div>
          One thing that jumps out are the thick traces that make their way around the whole board. 
          It is common to make a trace an additional 1mm wide for each Amp of current it will be carrying. 
          Through testing the motors, we saw that the only time they used more than 1A is when you apply a large amount of torque, 
          more than would be applied during the robots eventual operation. As such, I knew 1mm wide traces would be sufficient, 
          and I modified the width of all traces that would be carrying current to the motors, as well as all other 15V lines. 
          If I hadn't done so, when the robot encountered resistance (perhaps trying to drive into the wall), the motors would try to deliver more torque by using more current, 
          and would cause traces that were too thin to heat up, which could cause damage to the circuit.
          <br /><br />Another aspect related to the traces is the use of polygon pours on either side of the PCB. 
          They are both connected to GND, and serve a bunch of purposes. They provide a low resistance return path for all signals, 
          and they help with heat dissipation, just like the 1mm traces. They also make trace routing simpler, 
          and whenever there was a part of the polygon that wasn't actually connected to the rest of it, I could just add a via which connected it to the GND polygon on the back. 
          <br /><br />You'll notice a few decoupling capacitors. Most are at the "beginning" of the circuit (where it receives PWR and GND), so as to eliminate potential noise from the battery. 
          They are each have capacitances of different orders of magnitude, which together have the ability to remove incoming noise at a larger range of frequencies. 
          Additionally, C10 is placed right at the point where the gate driver is powered with 15V. This is just a final precaution to ensure the gate driver operates as expected. 
          <br /><br />Each side of the PCB has a connector. I chose the three carrying higher current to be screw terminals (since they have a higher current rating than JSTs). 
          Unfortunately, it turned out the only screw terminals we had access to were dimensioned differently to the footprints I had chosen. Luckily, I was able to find a fix, by using 3-pin JSTs and 
          cutting off the middle leg. As it turned out, the screw terminals weren't even needed, which was actually a blessing in disguise, since it was much quicker to plug and unplug JSTs (after we crimped all the wires of course). 
          <br /><br />One small note relating to the dimensions: while the size of the entire PCB is ~5x6cm, I made sure to separate the mounting holes by round numbers. That was just so I could easily tell Heejae 
          (who made the files for laser cutting the chassis) how far apart to place the holes in his design.
          <br /><br />While most if not all of the other teams who opted to make PCBs used the through-hole components available to us in the lab, I chose to buy my own SMDs for the resistors and capacitors. 
          To be honest, this choice was for fun, and because I knew we would have access to a reflow oven, which I had never used before. However, I discovered pretty quickly that without a stencil, placing the solder paste isn't much easier than soldering the tiny components by hand! 
          In the end, I was really happy with the H-bridge PCB I had created, and more importantly, it worked great. I received compliments from plenty of other teams on this PCB, which was great!
          <br /><br /><br /><br />This next circuit is perhaps more intriguing than the H-bridge, and you will see how it is tailored to fit the needs of our design. 
          The aim of this circuit is to use reflectance sensors (TCRT5000) to send signals to our MCU that indicate whether black tape is detected on the countertop. 
          The black tape indicates where the food stations are, so it is necessary to accurately detect it. 
          First things first, here is the schematic capture:
          <ZoomComponent 
            url="/images/reflecschematic.png"
            alt="Tape Sensing Circuit KiCad Schematic"
            className="bubs"
            buttonTop="26px"
            buttonLeft="700px"  
          />
          The TCRT5000s have an LED which shine onto the countertop, and each also have a phototransistor which receives the reflection of the light off the countertop. 
          The white table reflects much of the light, whereas the black tape doesn't, resulting in varying amounts of current let through by the phototransistor, which in conjunction with the rest of the circuit, 
          allows the robot to easily know when it has crossed a piece of tape. 
          The collector of the transistor is connected to 5V through a resistor, meaning when no light is shining on the transistor, the collector, which is connected to the non-inverting input of a comparator, is pulled up to 5V. 
          Meanwhile, the transistor acts a fully open passage when enough light is shining on it, bringing the comparator input down to GND. 
          <br /><br />We chose to use comparators in our design because we wanted a digital signal. A digital signal is simpler to work with, and the strategy our team chose didn't involve following the centre tape line, meaning we weren't using PID. 
          Finding the right voltage level for the inverting input of the comparator is not as simple as taking the mid point between GND and 5V, since the phototransistor is not so ideal to let in no current on the tape and act as a wire on the regular white countertop. 
          Some light always gets reflected of the tape, and some always absorbed by the countertop. I scoped the input to the comparator and used a makeshift white object with a piece of tape to wave over the sensor. 
          I noted the maximum and minimum values, and determined an appropriate midpoint value to be 3.1V. While we could have used a voltage divider to achieve this, we chose a potentiometer instead to not limit ourselves in terms of adjusting the value. 
          We always had to keep in mind that on the day of the competition, the tables are moved to a different room with different lighting, and we couldn't depend on nothing changing. 
          <br /><br />Since the comparator output is open-collector, a pull-up resistor is needed once again. When the non-inverting (+) input is greater than the inverting (-) input, the output is floating. When the opposite is true, it actively pulls down the output. 
          Therefore, the circuit will send a digital signal to the ESP: low when no tape is seen, and a high of 3.3V when tape is detected.
          <br /><br /><br />Now I will talk about the PCB. This one is much smaller than the H-bridge, however many of the considerations I mentioned before also apply to this one. 
          For example, I used a GND polygon pour on both sides, and made sure to make the separation of the mounting holes integer values.
          <div className="content-wrapper">
            <div className="image-content">
              <ZoomComponent 
                url="/images/reflecpcbeditor.png"
                alt="Tape Sensing KiCad PCB Editor"
                className="om-left"
                buttonTop="26px"
                buttonLeft="260px"  
              />            
            </div>
            <div className="image-content">
            <ZoomComponent 
                url="/images/reflec3dviewer.png"
                alt="Tape Sensing KiCad 3D Viewer"
                className="om-right"
                buttonTop="26px"
                buttonLeft="135px"  
              /> 
            </div>
          </div>
          The separation between the phototransistors of the TCRT sensors was designed to be exactly the width of the pieces of electrical tape, approximately 18mm. 
          I rotated the one on the right (which is not indicated in the image above) so as to allow the sensors to be close together, while keeping the correct width between the phototransistors themselves. 
          <br /><br />Additionally, we found in testing that the sensors worked best when position 2-3mm above the countertop. The TCRTs that we had access to in the ENPH lab had long leads, 
          which when soldered to the PCB near the base, were just the right length.
          <br /><br /><br /><br />I really enjoy trying to create the best PCBs I can, and I am very glad I took initiative to make these for our team. 
          They proved to be extremely consistent and worked flawlessly with the rest of our design. My only regret is that I didn't order them in a more exciting colour than green, however my team 
          and I weren't willing to wait the extra two days that PCBWay requires for other colours. That was the right call, as the entire project was done super quick, and an unnecessary two-day delay was not preferred. 
          <br /><br />Thank you for reading this section!
        </div>
    },
    {
      id: 2,
      title: "Power Circuits",
      description:
        "Each of our robots used two LiPo batteries, which each required their own circuit board to properly distribute power to the other components. Here I discuss my reasoning when designing them.",
      icon: "pcb",
      content:
        <div>
          Our robot needed power. So, that is exactly what I gave it! And I used two circuits, one for each battery, to power all the electrical components inside the robot. 
          One of the LiPos was 4 cells (16.8V), used to power the motors, and the other was 2 cells (8.4V), used to power the ESP and other electrical components. 
          I soldered switches onto the positive line of each of them, as well as a 10A fuse on the 4-cell battery. We never ended up blowing the fuse, but it was a necesssary precaution to have. 
          <br /><br />When it came to designing the circuits, the first consideration was what voltage level/levels were needed. 
          For the 4-cell, we didn't necessarily need to adjust the voltage, and we could have powered the motors with 16.8V. 
          However, as the batteries lose charge, their voltage will decrease and the motors will spin slightly slower. 
          If we let that happen and eventually decided to hard-code a portion of the robots movement, it could lead to problems. 
          For example, having it drive forward for 500ms would go farther on a full charge than a low charge. 
          <br /><br />As such, we used buck converters to step down to 12V. Buck converters are efficient, and are exactly what we needed given the amount of current we would be drawing from the battery.  
          A component such as a voltage regulator in this scenario would burn too much power. 
          The buck converters were rated for 2.5A, and given the approximate 1A max that we observed in the motors, 
          we would need three of them.
          <br /><br />Without further ado, take a look at the image below. I unfortunately never took a photo of the finished circuit, and we no longer have access to the robots. 
          I designed the circuit by taking photos, overlaying them, and drawing on them.
          <img src="/images/4cellpowercirc.jpg" alt="4-Cell LiPo Power Circuit" className="modal-image" />
          <br />The main thing that sticks out is the connection on the left side. 
          I dedicated a large area to cover with solder in order to secure the massive wires to the board. 
          Additionally, I cut the board so that a ziptie could easily be placed to further secure the wires. 
          Without the ziptie, when the wires get pulled around, there is stress on the solder connection, which could eventually become damaged. 
          The ziptie moves the pivot point off of the solder connection, and pretty much no amount of yanking would be able to break it. 
          <br /><br />We always made sure to twist the power and GND wires around each other to reduce any electromagnetic effects. 
          We made an effort to keep our cable management organized, and we never had any issues with noise in our circuits.
          Additionally, you'll notice that I placed two capacitors between power and GND. Batteries are a very common source of noise in circuits, 
          and these capacitors are the first line of defense against that. If you read the PCB Design section, you will see the use of additional decoupling capacitors.
          <br /><br /><br /><br />
          The main consideration for the smaller circuit was which voltages were needed, and in which JST configurations. 
          This time, buck converters would be overkill, and voltage regulators would do the trick.
          This is because much less current was being drawn, as it wasn't powering the motors. 
          We needed to bring the 8.4V down to 5V and 3.3V, and once all the connections were planned out, the circuit was pretty much done. 
          I added capacitors like before for noise reduction, and we also later added an indicator LED which let us know if we ever accidently forgot to flip the switch off when we weren't running the robot 
          (and we did the same for the other power circuit).
          <img src="/images/2cellpowercirc.jpg" alt="2-Cell LiPo Power Circuit" className="modal-image" />
          <br />Finally, take a look at our first robot at an early stage, when we were just getting the wheels spinning. If you look carefully at the top side, 
          you can see both the power circuits. For the smaller one, we added heat sinks on the voltage regulators, as well as some extra connectors.
          <video muted controls className="power-circuit-video">
            <source src="/images/powercircuitvid.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <br /><br /><br />Thanks for checking out this section! Please be sure to check the other sections (especially the firmware ones)!<br /><br /><br /><br />
        </div>
    },
    {
      id: 3,
      title: "Countertop Navigation",
      description:
        "These robots were autonomous, meaning we couldn't be there to control where they went. Instead, I programmed a numbering system to allow the robot to be able to deduce how to navigate between any two stations on the countertop.",
      icon: "mcu",
      content:
        <div>
          Naturally, the first portion of the robot to be built is the base and drive train.
          As such, the corresponding firmware is where I began. The task at hand was to create a system that would allow the robot to autonomously traverse between the different food stations, which for each robot were spread along two counters. 
          While there was a centre tape line to follow (indicated in the annotated diagram below),
          we instead decided to drive along the counter, a strategy that multiple other teams transitioned to later on.
          We reasoned that moving along the wall would be much faster for traversing between two stations on the same counter.
          Before I began writing the code, I created a text file outlining the navigation approach.
          The following is mostly the same as in the file: <br /> <br />
          When driving from one food station to another, if necessary, the robot will cross over to the other counter by driving up or down (denoted U and D in the diagrams below). 
          Here it is important to mention that we chose to use mecanum wheels, which by powering the wheels in particular directions, allowed our robots to drive in any direction without having to turn beforehand. 
          This was key if we were going to be right up against the counter.
          The robot will then stop approximately halfway between the two counters, spin 180 degrees, and then continue the rest of the distance to the other counter. 
          Next, it will drive forwards or backwards (F or B) until it reaches the desired node (which is the only step if the robot is already at the desired counter). 
          Each node is denoted by a different number, and notice that not every node is a food station (there are two numbering systems, one for the top robot, and one for the bottom robot).
          <div className="content-wrapper">
            <div className="image-content">
              <img src="/images/both_counters_unannotated.png" alt="Counter node diagram unannotated" className="gm-left" />
            </div>
            <div className="image-content">
              <img src="/images/both_counters.jpg" alt="Counter node diagram annotated" className="gm-right" />
            </div>
          </div>
          <br />The numbers of any two nodes that line up vertically always differ by 10. 
          Based on whether our current node number is above or below 10, and if the destination node is the opposite, the robot must cross counters.
          For both robots, whenever we cross to the other side, we can simply add or subtract 10 to determine the node we have arrived at.<br /> <br />
          Once we are on right side, we will arrive at the destination node by counting the number of tape pieces the robot crosses as it drives along the counter. 
          This part differs slightly for the two robots, so I will address the bottom bot first, referencing the following diagram. 
          <img src="/images/bottom_counter.jpg" alt="Bottom counter node diagram" className="modal-image" />
          <br />As you can see, every node on the top side has a tape piece. 
          This makes it simple; the difference between the number of the node the bottom bot is at and the one it is travelling to is equal to the number of tape pieces it must cross. 
          On the bottom side, not every node has a tape piece. 
          This time, we can say that if the node number is greater than our current node, we must move forwards by one tape piece, and if it less than our current node, we must move backwards by one tape piece.
          There is also the serving area to consider. It doesn't have a tape piece, and is instead indicated by an IR signal. While we had a signal detection circuit and cross correlation code, 
          we chose to use an ESP32 timer and hard code the timings required to drive to the serving area. Humurously, the instructors never even installed the IR LED since no teams were actually using it. 
          <img src="/images/top_counter.jpg" alt="Top counter node diagram" className="modal-image" />
          <br />The top bot is more complicated, as the food stations on either side never line up with each other. 
          This introduces many more nodes, however both sides can be dealt with the same way. 
          We take the difference between nodes numbers, divide by two, and round up. This gives us the number of tape pieces away the desired node is. 
          For example, if we are moving from node 11 to 16, we calculate: 16 - 11 = 5, 5 / 2 = 2 (integer division), 2 + 1 = 3 (the “rounding”). 
          Therefore the third tape piece we reach in the forward direction (forward because 16 &gt; 11) marks the desired node.
          <br /><br /> The tape is counted is done with the help of the reflectance sensors, which are incorporated into one of the PCBs explained in the PCB Design section.
          Every time the reflectance sensor detects black tape, an interrupt function is triggered that increments a global variable. 
          To avoid incrementing the counter multiple times for the same piece of tape, one of the ESP32's four timers is used. 
          When we first increment the counter, we start the timer, and the next time the interrupt is called, if the timer has not reached ~500ms, we won’t increment the counter. 
          The robot will definitely have crossed the tape piece in 500ms, and definitely won’t have reached the next one in 500ms. 
          Below are a couple relevant code snippets; on the left is the tape interrupt function, and on the right are the Station objects. Each station has an node number,
          two values related to distances for the movements of the sweeper arm and elevator platform, and a final variable used for the stations on which items were passed from the top bot to the bottom bot.
          <div className="content-wrapper">
            <div className="image-content">
              <img src="/images/tape_interrupt.png" alt="Tape interrupt function" className="im-left" />
            </div>
            <div className="image-content">
              <img src="/images/stations.png" alt="Station objects" className="im-right" />
            </div>
          </div>
          <br /> Once the tape interrupt sets arrived to be true, our robot stops. At this point, if we didn't do anything, the robot would have overshot the piece of tape by over 5cm, 
          and assuming we placed the food item in the centre of the station (aligned with the tape), the sweeper would miss the item. So, we instead immediately start driving in reverse,
          which brings the robot to a stop faster, and then continue reversing (at a slower speed) until the tape is seen again. Since we reverse slower, this time it only overshoots by about 1cm, which is not an issue.
          <br /><br />Hopefully the navigation explanation made sense and came across as simple, after all simple is much better than overcomplicated, and I tried my best to avoid that.
          However, there were plenty of additional considerations and edge cases that had to be dealt with by modifying the explained system.
          I don't believe any of them were sctrictly necessary, but were improvements that drastically increased the speed at which our robot could assemble burgers.
          I'll cover some of the most important ones. 
          <br /><br />As you can see in the earlier counter diagrams, the tomato, plates, cheese, and lettuce stations are each at the edge of the counter, and with our robot aligned on the tape,
          our wheels only have a few centimetres to spare from the edge of the table. If we came at full speed, saw the tape, and tried to back up, the front wheels would most likely be dangling off the table 
          and it would get stuck. Obviously we don't want to have to just drive slower, since we're pushing ourselves to try and win the competition. So, we need to pre-emptively slow down. And, there was a fairly elegant solution,
          which was to slow down at the second-to-last piece of tape. This worked just fine, and you can see it in action at the beginning of this test run video. 
          <video muted controls className="test-run-video">
            <source src="/images/robot_test_run.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <br />I'll just mention something about the video; notice how the run ultimately ended because the robot didn't spin accurately enough.
          The timings were set values, and even after calibration, it acted differently based on which counter we were on. Sometimes it overspun, sometimes it underspun. 
          We considered a multitude of fixes, including adding another tape sensing circuit which could use the centre tape line to know when we've spun enough. 
          However, it didn't seem reliable enough and was sure to create plenty of other issues along the way. We could drive into the wall for longer to straighten the robot out, 
          but that would waste seconds every time the robot crosses counters. Ultimately, I fixed the issue with a very simple modification, which entailed starting the spin as close to 
          the other side as possible. The robot would drive 80% of the way across, spin, and then drive the last little bit. That way, if it overspun or underspun, 
          there would be very little time spent driving that could cause that error to actually lead the robot off course, since the robot would instead collide with the wall and straighten out almost immediately. 
          <br /><br />Let's jump back to the slowing down before reaching the tape on the edge of the table.
          When the bottom bot is travelling to the cheese or lettuce stations, there is no second-to-last piece of tape at which to slow down. 
          This time, the workaround was to dedicate another hardware timer to timing exactly when to slow down. 
          The timing had to be different based on which node was coming from, but once we got them all right, our robot could drive anywhere on the counter without wasting time or having to worry about falling off the table.
          <br /><br />
          Thanks for reading, and if you're interested, click&nbsp; 
          <a href="https://github.com/mcappellano/light-side">
            here
          </a> 
          &nbsp;to see the GitHub repo. <br /><br /><br /><br /><br />
        </div>
    },
    {
      id: 4,
      title: "Arm & Elevator Control",
      description:
        "As is seen in the video above, our robots swept in items with long arms, as well as lowered/raised their internal elevators. Achieving that precision and coordination took time and testing.",
      icon: "mcu",
      content:
        <div>
          Now that our robot was well on it's way to being fully constructed, I could get started on testing approaches for controlling the sweeper and elevator. 
          To clarify what I mean by sweeper (aka arm) and elevator, here is a video of them moving simulatenously.
          <video muted controls className="adjust-demo-video">
            <source src="/images/burger_adjust_demo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <br />I filmed the above video to send to the rest of my team after I had got it to work. 
          It's purpose was to adjust the burger into a more well-stacked position before serving it. 
          First, the sweeper retracts to pull the top bun onto the rest of the burger, then the elevator raises, 
          and the sweeper retracts back a small amount so that the patty and top bun became centred on the burger. 
          It would take a while to explain why this was the best way to fix it, but it made a noticeable difference in the quality of burgers it served. 
          The point of this section is to explain the code (as well as some electrical components) that allowed me to make create movement sequences like the one shown. 
          <br /><br />
          The essence of the design were two rails, one horizontal for the sweeper, and one vertical for the elevator. 
          Each was connected through a gear mechanism to a rotary encoder. 
          Additionally, we placed limit switches as "resets", so that any small errors in the signal received from the encoders would not accumulate. 
          Each encoder and switch were attached to their own interrupts. I'll start by explaining the encoder interrupts.
          <img src="/images/sweepencoderinterrupt.png" alt="Rotary encoder interrupt for sweeper" className="interrupt" />
          <br />Just a couple quick notes: Hopefully it is clear from my code that I try to make it as readable as possible. 
          For this project I added high level comments for some functions, however if I was working with a team, 
          I would have added more elaborate comments in the header files. 
          Additionally, I try my best to avoid magic numbers, instead creating variables with descriptive names. 
          I always add white space in what I take to be a sensible manner (messy code bothers me). 
          <br /><br />The most interesting part of this function is how it handles the signals from the encoder. 
          But before I explain the above method, I'll explain what we were originally using:<br />
          <img src="/images/oldsweepinterrupt.png" alt="Old rotary encoder interrupt for sweeper" className="bubs" />
          <br />In order to explain, I will reference the following diagram for quadrature encoders:
          <img src="/images/oldencoderdiagram.jpg" alt="Pulse channels for quadrature encoders (previous method)" className="subs" />
          <br />In separate initialization code, the interrupt function is set to be triggered upon a detected rising edge in SWEEP_ENCODER_1, named Channel A in the diagram. 
          I've marked red dots indicating the values of the two channels at the rising edges of Channel A. A simple differentiation between a clockwise and counterclockwise rotation was checking whether or not the values from the two channels were equal, as is done in the code above. 
          This worked well and provided enough precision for the sweeper, but the elevator required more precision. Given our gear ratio, each pulse we measured from the elevator's encoder indicated 1.636mm of vertical displacement (this constant is referred to as SWEEP_PULSE_DISTANCE in the code). 
          Our strategy involved aligning the partially stacked burger perfectly at the height of the counter to sweep on the next item, and in combination with random variation, an accuracy of only about 2mm wasn't ideal. 
          To improve on this, my teammate and I updated the method in which we analyzed the encoder channels. 
          By reading the current values and comparing them with the <i>previous</i> values. This can be done at every transition of either channel, which is 4 times more often than the previous method, as indicated in the below diagram.<br />
          <img src="/images/newencoderdiagram.jpg" alt="Pulse channels for quadrature encoders (updated method)" className="subs" />
          <br />Here is the relevant part of the updated interrupt function:
          <img src="/images/sweepinterruptfirst.png" alt="Rotary encoder channel analysis" className="bubs" />
          <br />The first few lines of the function perform bitwise operations to combine the 2 new values with the two previous.
          This leaves us with a single 4-bit value that can directly indicate whether the function call was triggered by a cw or ccw encoder rotation. 
          By using a table to keep track of combinations of values and referencing the quadrature diagram it isn't too difficult to account for all the possible scenarios.
          I remember being happy to see this working the first time we tested it. 
          <br /><br />With this change, our accuracy for the elevator was now 0.409mm, which helped marginally. 
          Nevertheless we later changed our strategy just slightly, which mostly nullified the benefit that this modification provided. 
          We made it so that the items being swept on would drop down onto the burger. 
          This was because some counters were higher than others, and when the competition surfaces would get moved down and rebuilt in the theatre, the heights could change on the day of the competition. 
          This meant it would be too risky to rely on having our robots perfectly align with the height of the counters. 
          <br /><br />I'll now explain the second half of the interrupt function and how it accounted to various aspects of our mechanical design. 
          Once again, here is the relevant code section:<br />
          <img src="/images/sweepinterruptsecond.png" alt="Sweep encoder interrupt distance checks" className="bubs" />
          <br />After sweepCounter is updated in the first part of the interrupt, 
          there are two checks being made in the second part. 
          The first check determines if the item has been swept onto the burger far enough. 
          This check only happens when the sweeper is retracting, and has yet to be stopped. 
          The number of pulses counted in sweepCounter is then compared to the number of desired ticks, which is in turn calculated by taking the distanceToSweep (in mm, and determined by the current station the robot is at), 
          and dividing it by the SWEEP_PULSE_DISTANCE (in mm per encoder tick). 
          The sweeper would carry enough momentum to travel a short distance even after the PWM signal to the motor had ceased. 
          I empirically determined the OVERSHOOT_DIST to be 9.8mm. 
          This value would be affected by changes in things such as the speed or mass of the sweeper, or the design of the gear system that connected the rail to the motor and encoder. 
          Once I accounted for the overshooting, and the motor turned off when the sweeper was 9.8mm from the final position, it lined up perfectly with where it needed to be. 
          <br /><br />The other check at the end of the interrupt function slows down the motor if it has extended a certain distance. 
          At first, I had the sweeper extending at a constant speed. However in testing, the acrylic gears we were using broke, multiple times. 
          It happened because of the sudden force applied to the gears when the sweeper went from extending to being stationary. 
          In addition to waterjet cutting new gears out of polycarbonate (which is less brittle than acrylic), I added the check to slow down the sweeper before it reaches the end. 
          The only time it is extending <i>not</i> to prepare for sweeping the next item is serving the plate, which is why I added the condition that the current station cannot be the serving area. 
          <br /><br />That was the essence of how the firmware controlled the sweeper, as well as the elevator, which was mostly the same. 
          If you rewatch the video at the beginning of this section, you will notice that each action happens exactly when the previous terminates. 
          Did I measure the timings and add the exact right delays between each function call? Well, while running that sequence in an actual competition run, 
          the delay would block the rest of the code from being able to perform certain actions, such as changing how the wheels were being powered. 
          So maybe I could have used timers, and perhaps that would allow me to introduce some overlap between the end of one step and the start of another. 
          However time for this sequence was not too tight, and instead of using timers, I introduced booleans that upon the completion of a step, if set to true would automatically begin the next step.<br />
          <img src="/images/stopsweeperfunction.png" alt="stopSweeper function" className="lubs" />
          <br />The above function is called either when the encoder interrupt counts enough pulses, or when the limit switch is pressed while the sweeper is extending. 
          In addition to setting the PWM output to 0, it performs checks on two variables which were set when the sweeper was originally instructed to extend or retract. 
          This way, as soon as the sweeper stops, the elevator starts, without having to rely on timing.
          <br /><br />Thank you for reading this final section! I hope it was interesting; I know it sure was when my team and I were working on it.
          <br /><br /><br /><br />        
        </div>
    },
  ];
  
  export default timelineElements;